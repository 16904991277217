import React, { useState, useEffect } from 'react';
import computer from '../assets/computer.png';
import { Formik, Field, Form, isInteger } from 'formik';
import eye from '../assets/eye.svg';
import axios from 'axios';
import qs from 'query-string';

export default function Formular({ dealer, setSuccess, setLoading, setError }) {
	const [handelnummer, setHandelnummer] = useState(dealer.dealer_number);
	const [name, setName] = useState(dealer.name);
	const [ort, setOrt] = useState(dealer.ort);
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const [privacy, setPrivacy] = useState(false);
	console.log(privacy);
	const submitData = (values) => {
		setLoading(true);
		values.registration_token = qs.parse(
			window.location.search
		).registration_token;
		axios
			.post('https://backend.toyota-toolbox.at/api/tam/v1/register', values)
			.then((res) => {
				setSuccess(true);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				setError(error?.response?.data?.message);
			});
	};

	const defaultValues = {
		anrede: '',
		firstname: '',
		lastname: '',
		email: '',
		password: '',
		role: 'sales',
		schulungstermin: '',
	};
	const validatePassword = (values) => {
		let error = '';
		const passwordRegex = /(?=.*[0-9])/;
		if (!values) {
			error = '*Required';
		} else if (values.length < 8) {
			error = 'Das Passwort muss mindestens 8 Zeichen lang sein.';
		} else if (!passwordRegex.test(values)) {
			error = 'Das Passwort muss eine Zahl beinhalten.';
		}
		return error;
	};

	const validateConfirmPassword = (pass, value) => {
		let error = '';
		if (pass && value) {
			if (pass !== value) {
				error = 'Die Passwörter stimmen nicht miteinander überein.';
			}
		}
		return error;
	};

	const validateEmail = (values) => {
		let error = '';
		const emailRegex =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		if (!values) {
			error = 'Pflichtfeld';
		} else if (!emailRegex.test(values)) {
			error = 'Ungültige E-Mail Adresse';
		}
		return error;
	};

	return (
		<div id="register-bottom-box">
			<div>
				<div className="bottom-box">
					<div className="sub-title">Händlerdaten</div>
					<div>
						Bei diesem Registrierungslink sind folgende Händlerdaten hinterlegt:
					</div>
					<label htmlFor="handelnummer">Händlernummer</label>
					<input
						className="disabled"
						type="text"
						id="handelnummer"
						value={handelnummer}
						disabled
					/>
					<label htmlFor="name">Name</label>
					<input
						className="disabled"
						type="text"
						id="name"
						value={name}
						disabled
					/>
					<label htmlFor="ort">Ort</label>
					<input
						className="disabled"
						type="text"
						id="ort"
						value={ort}
						disabled
					/>
				</div>
				<div className="bottom-box" style={{ marginTop: '20px' }}>
					<div className="sub-title">Info</div>
					<div>
						Nach erfolgreicher Eingabe Ihrer Kontaktdaten, wird für Sie ein
						persönlicher Account angelegt. Dazu erhalten Sie an die von Ihnen
						angegebene Mailadresse eine Bestätigung, sobald Ihr Account
						aktiviert wurde. Nach dem Erhalt dieser Bestätigungsmail können Sie
						die Inhalte und Funktionen der Toyota Toolbox uneingeschränkt
						nutzen. Bei Rückfragen oder auftretenden Problemen während des
						Registrierungsprozess, wenden Sie sich bitte an folgende
						Mailadresse: mail@toyota-toolbox.at
					</div>
				</div>
			</div>
			<div>
				<div className="bottom-box">
					<div className="sub-title">Ihre Daten</div>
					<Formik
						initialValues={defaultValues}
						onSubmit={(values, actions) => {
							submitData(values);
						}}
					>
						{(props) => (
							<Form>
								<label htmlFor="anrede">Anrede</label>
								{console.log(props.values)}
								<Field as="select" name="anrede" id="anrede">
									<option value="Herr">Herr</option>
									<option value="Frau">Frau</option>
									<option value="Divers">Divers</option>
								</Field>
								<label htmlFor="firstname">Vorname</label>
								<Field type="text" name="firstname" id="firstname" />
								<label htmlFor="lastname">Nachname</label>
								<Field type="text" name="lastname" id="lastname" />
								<label htmlFor="email">E-Mail Adresse</label>
								<Field
									type="email"
									name="email"
									id="email"
									validate={validateEmail}
								/>
								{props.errors.email && (
									<div className="error">{props.errors.email}</div>
								)}
								<label htmlFor="password">Passwort</label>
								<div style={{ position: 'relative' }}>
									<Field
										type={showPassword ? 'text' : 'password'}
										name="password"
										id="password"
										validate={validatePassword}
									/>
									<img
										className="eye"
										src={eye}
										onClick={() => setShowPassword(!showPassword)}
									/>
								</div>
								{props.errors.password && (
									<div className="error">{props.errors.password}</div>
								)}

								<label htmlFor="confirmPassword">
									Wiederholen Sie Ihr Passwort
								</label>
								<div style={{ position: 'relative' }}>
									<Field
										type={showConfirmPassword ? 'text' : 'password'}
										name="confirmPassword"
										id="confirmPassword"
										validate={(value) =>
											validateConfirmPassword(props.values.password, value)
										}
									/>
									<img
										className="eye"
										src={eye}
										onClick={() => setShowConfirmPassword(!showConfirmPassword)}
									/>
								</div>
								{props.errors.confirmPassword && (
									<div className="error">{props.errors.confirmPassword}</div>
								)}
								<label>Ihre Rolle im Autohaus</label>
								<div className="checkbox" role="group">
									<Field name="role" as="select">
										<option value="sales">Sales</option>
										<option value="aftersales">Aftersales</option>
										<option value="management">Management</option>
										<option value="administration">Administration</option>
									</Field>
								</div>
								<label>Webinartermin</label>
								<div className="checkbox" role="group">
									<Field name="schulungstermin" as="select">
										<option value="">Bitte auswählen</option>
										<option value="1. Oktober 2021 – 15.00 Uhr">
											1. Oktober 2021 – 15.00 Uhr
										</option>
									</Field>
								</div>
								<label>Datenschutz / Bedingungen</label>
								<div className="privacy-box">
									<input
										style={{ height: '16px', width: '16px' }}
										type="checkbox"
										name="privacy"
										id="privacy"
										value={privacy}
										onChange={(e) => setPrivacy(e.target.checked)}
									/>
									<label htmlFor="privacy">
										Mit der Registrierung zur Toyota Toolbox Austria stimmen Sie
										der Speicherung Ihrer Daten zu. Diese Daten stehen
										ausschließlich der Toyota Austria GmbH und der 12-05
										Solutions GmbH als umsetzender Dienstleister zur Verfügung.
										Die Speicherung Ihrer Daten ist ein und allein für die
										Nutzung der Toyota Toolbox und ihrer Funktionen
										erforderlich. Ihre Daten werden in keiner Weise an
										Drittanbieter etc. weitergegeben.
									</label>
								</div>
								{privacy ? (
									<button className="submit-button" type="submit">
										Jetzt anmelden
									</button>
								) : (
									<button className="submit-button" type="submit" disabled>
										Jetzt anmelden
									</button>
								)}
							</Form>
						)}
					</Formik>
				</div>
			</div>
		</div>
	);
}
