import React, { useState, useEffect } from 'react';
import computer from '../assets/computer.png';
import logo from '../assets/toyota-logo.svg';
import { useParams } from 'react-router-dom';
import Loader from './Loader';
import Formular from './Formular';
import Success from './Sucess';
import qs from 'query-string';
import axios from 'axios';
import Error from './Error';

export default function Main() {
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);
	const [dealerDaten, setDealerDaten] = useState();
	const [error, setError] = useState(false);

	useEffect(() => {
		let registration_token = qs.parse(
			window.location.search
		).registration_token;
		axios
			.post('https://backend.toyota-toolbox.at/api/tam/v1/registration-data', {
				registration_token: registration_token,
			})
			.then((res) => {
				setDealerDaten(res.data);
				setLoading(false);
			})
			.catch((error) => {
				setError(
					'Sie haben keinen Zugriff auf die Anmeldung. Bitte kontaktieren Sie mail@toyota-toolbox.at, falls Sie Probleme haben zuzugreifen.'
				);
				setLoading(false);
			});
	}, []);

	console.log(error);

	return (
		<div id="register">
			<div id="register-top-box">
				<img className="logo" src={logo} />
				<div className="top-box-content">
					<div className="sub-title">Jetzt anmelden</div>
					<div className="big-title">Die Toyota Toolbox</div>
					<div>
						Ab sofort können Sie für die Unterstützung des Bereichs After Sales
						die Toyota Toolbox nutzen. Erhalten Sie alle Informationen zu
						laufenden Kampagnen, Aktionsprodukten und -rabatten, Preisen und
						vielem mehr!
						<br /> Für die Nutzung der Toyota Toolbox ist eine Registrierung
						erforderlich. Bitte füllen Sie die nachfolgenden Felder aus, um
						Ihren persönlichen Zugang zur Toyota Toolbox zu erhalten.
					</div>
				</div>
				<img className="computer" src={computer} />
			</div>
			<div id="bottom">
				{loading && <Loader />}
				{error && <Error error={error} setError={setError} />}
				{dealerDaten && !success && (
					<Formular
						dealer={dealerDaten}
						setSuccess={setSuccess}
						setLoading={setLoading}
						setError={setError}
					/>
				)}
				{success && <Success />}
			</div>
		</div>
	);
}
