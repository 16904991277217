import React, { useState, useEffect } from "react";
import errorIcon from "../assets/error.svg";
import qs from "query-string";

export default function Error({ error, setError }) {
    return (
        <div style={{ marginBottom: "20px" }} className='bottom-box success'>
            <img style={{ height: "150px" }} src={errorIcon} />
            <div>{error}</div>
            {error !==
                "Sie haben keinen Zugriff auf die Anmeldung. Bitte kontaktieren Sie info@toyota-toolbox.at, falls Sie Probleme haben zuzugreifen." && (
                <div
                    style={{ marginTop: "16px", cursor: "pointer" }}
                    onClick={() => {
                        setError(false);
                    }}>
                    Schließen
                </div>
            )}
        </div>
    );
}
