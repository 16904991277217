import "./clean.scss";
import "./App.scss";
import Main from "./componenets/Main";
import Bestatigung from "./componenets/Bestatigung";
import { Route, Switch, useHistory } from "react-router-dom";

function App() {
    return (
        <div className='App'>
            <Switch>
                <Route path='/confirm' component={Bestatigung} />
                <Route path='/' component={Main}></Route>
            </Switch>
        </div>
    );
}

export default App;
