import React, { useState, useEffect } from "react";
import success from "../assets/success.svg";

export default function Success() {
    return (
        <div className='bottom-box success'>
            <img style={{ height: "150px" }} src={success} />
            <div className='sub-title'>Anmeldung erfolgreich</div>
            <div>
                Ihre Anmeldung war erfolgreich - bitte bestätigen Sie Ihre
                E-Mail Adresse.
            </div>
            <div>
                Im Anschluss der Freigabe unsererseits können Sie Ihren Zugang
                nutzen.
            </div>
        </div>
    );
}
