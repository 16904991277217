import React from "react";
import loader from "../assets/preloader.svg";
export default function Loader({ backgroundWhite }) {
    return (
        <div
            style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
            <img src={loader} style={{ height: "400px" }} />
        </div>
    );
}
