import React, { useState, useEffect } from 'react';
import successIcon from '../assets/success.svg';
import axios from 'axios';
import qs from 'query-string';
import Loader from './Loader';
import computer from '../assets/computer.png';
import logo from '../assets/toyota-logo.svg';

export default function Bestätigung() {
	const [success, setSuccess] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let confirm_token = qs.parse(window.location.search).confirm_token;
		axios
			.post('https://backend.toyota-toolbox.at/api/tam/v1/confirm', {
				confirm_token: confirm_token,
			})
			.then((res) => {
				if (res.data.success === true) {
					setSuccess(true);
					setLoading(false);
				} else {
					setLoading(false);
				}
			})
			.catch((err) => {
				setLoading(false);
			});
	}, []);

	if (loading) {
		return <Loader />;
	} else {
		return (
			<div id="register">
				<div id="register-top-box">
					<img className="logo" src={logo} />
					<div className="top-box-content">
						<div className="sub-title">Jetzt anmelden</div>
						<div className="big-title">Die Toyota Toolbox</div>
						<div>
							Ab sofort können Sie für die Unterstützung des Bereichs After
							Sales die Toyota Toolbox nutzen. Erhalten Sie alle Informationen
							zu laufenden Kampagnen, Aktionsprodukten und -rabatten, Preisen
							und vielem mehr!
							<br /> Für die Nutzung der Toyota Toolbox ist eine Registrierung
							erforderlich. Bitte füllen Sie die nachfolgenden Felder aus, um
							Ihren persönlichen Zugang zur Toyota Toolbox zu erhalten.
						</div>
					</div>
					<img className="computer" src={computer} />
				</div>
				<div id="bottom">
					<div className="bottom-box success">
						{success ? (
							<React.Fragment>
								<img style={{ height: '150px' }} src={successIcon} />
								<div className="sub-title">E-Mail bestätigt</div>
								<div>
									Ihre E-Mail Adresse für die Toyota Toolbox wurde erfolgreich
									bestätigt.
								</div>
								<div>
									Im Anschluss der Freigabe unsererseits können Sie Ihren Zugang
									nutzen.
								</div>
							</React.Fragment>
						) : (
							<div>Es gibt einen Fehler bei Email Bestätigung</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}
